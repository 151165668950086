<template>
  <v-container class="we-love-trees">
    <breadcrumb :items="breadcrumb" />
    <h1 class="mb-3" v-html="title"></h1>
    <p v-html="content" />
    <WeLoveTreesFilter />
    <v-row>
      <v-col
        cols="12"
        sm="6"
        md="4"
        lg="3"
        v-for="post in postList"
        :key="post.id"
      >
        <PostCard :post="post" type="we_love_trees" />
      </v-col>
    </v-row>
    <v-pagination
      class="mt-12"
      color="primary"
      v-if="pager && pager.totPages > 1 && !$vuetify.breakpoint.xs"
      :value="pageFilter"
      :page="pager.selPage"
      :length="pager.totPages ? pager.totPages : 0"
      :totalVisible="6"
      @next.stop.prevent="handlePageFilter"
      @prev="handlePageFilter"
      @input="handlePageFilter"
    ></v-pagination>
  </v-container>
</template>
<script>
import CMService from "~/service/cmService";
import PostCard from "@/components/wordpress/PostCard.vue";
import WeLoveTreesFilter from "@/components/wordpress/WeLoveTreesFilter.vue";
import Breadcrumb from "@/components/navigation/Breadcrumb.vue";

import page from "@/commons/mixins/page";
import clickHandler from "~/mixins/clickHandler";

export default {
  name: "WeLoveTreesList",
  data() {
    return {
      postList: null,
      newFilters: false,
      pager: {},
      noPostToShow: false,
      queryParams: null
    };
  },
  mixins: [clickHandler, page],
  components: { PostCard, WeLoveTreesFilter, Breadcrumb },

  methods: {
    async fetchPosts(pageNum) {
      let params = [];
      //this.page = pageNum || this.pageFilter;
      this.pager.selPage = pageNum;
      params.push({
        filterName: "page",
        filterValue: this.pager.selPage
      });

      if (this.queryParams.length) {
        const filterValues = this.queryParams
          .split(",")
          .map(value => parseInt(value));
        const filterParams = {
          filterName: "categoria_wlt",
          filterValue: filterValues
        };
        params = [...params, filterParams];
      }

      const res = await CMService.getCustomPostByFilters(
        "we_love_trees",
        params,
        12
      );
      if (res && res.data && res.data.length) {
        this.pager = res.page;
        this.postList = res.data;
      } else {
        this.postList = null;
      }
    },
    handlePageFilter(page) {
      var _this = this;
      this.fetchPosts(page);
      setTimeout(function() {
        _this.$vuetify.goTo(0);
      }, 300);
    }
  },
  mounted() {
    this.page = this.$route.params.page;
    this.queryParams = this.$route.query.filter || "";
    this.fetchPosts();
  },
  watch: {
    async "$route.query.filter"() {
      this.queryParams = this.$route.query.filter;
      this.fetchPosts();
    }
  }
};
</script>
