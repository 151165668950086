<template>
  <div class="we-love-trees-filter pa-5 green rounded-md" v-if="filters">
    <h4 class="text-uppercase white--text mb-3">
      {{ $t("weLoveTrees.filter.title") }}
    </h4>
    <v-row class="justify-md-space-between justify-lg-start align-center">
      <v-col
        cols="12"
        md="3"
        v-for="(filter, index) in filters"
        :key="filter.id"
      >
        <v-select
          :items="filter.children"
          :label="filter.text"
          v-model="models[index]"
          color="green"
          item-color="green"
          class="white default--text rounded-0 mb-3 mb-md-0"
          append-icon="$chevronDown"
          single-line
          outlined
          hide-details
        >
        </v-select>
      </v-col>

      <v-col cols="12" md="auto">
        <v-btn @click="search" class="rounded-pill elevation-0 pa-6 w-100"
          >{{ $t("cta.search") }}
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>
<style lang="scss" scoped>
.we-love-trees-filter {
  h2 {
    line-height: inherit;
  }
  .v-input__icon--append .v-icon {
    color: --var(--var-green-base);
  }
}
</style>

<script>
import CMService from "~/service/cmService";

export default {
  name: "WeLoveTreesFilter",
  data() {
    return {
      filters: null,
      models: []
    };
  },
  methods: {
    async fetchCategories() {
      const categories = await CMService.getCustomPostByFilters(
        "categoria_wlt",
        null,
        100
      );
      const categoryTree = categories.data.reduce((acc, item) => {
        if (item.parent === 0) {
          const children = categories.data
            .filter(child => child.parent === item.id)
            .map(child => ({
              text: child.name,
              value: child.id,
              id: child.id
            }));
          acc.push({
            text: item.name,
            value: item.id,
            children
          });
        }
        return acc;
      }, []);

      this.filters = categoryTree;
      // this.models = Array(this.filters.length);
    },
    search() {
      const categoryIds = this.models
        .filter(category => category != null)
        .join(",");

      if (categoryIds.length) {
        this.$router.push({
          path: "/we-love-trees",
          query: { filter: categoryIds }
        });
      }
    }
  },
  mounted() {
    this.fetchCategories();
  }
};
</script>
